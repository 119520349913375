<template>
  <a-layout class="transaction-form">
    <TitleBreadcrumb />
    <a-spin :spinning="submitting" :tip="labels.submittingText">
      <div class="content-base container">
        <a-card class="transaction-form-card" id="transaction-print-area">
          <ValidationObserver ref="observer">
            <a-form
              :label-width="80"
              slot-scope="{ validate }"
              @submit.prevent=""
            >
              <a-row type="flex" :gutter="[8, 8]">
                <a-col :span="24">
                  <a-radio-group
                    v-model="form.type"
                    :size="isTabletDown ? 'small' : 'large'"
                  >
                    <a-radio-button
                      v-if="
                        $route.query.type === TYPE.DEPOSIT || !$route.query.type
                      "
                      :value="TYPE.DEPOSIT"
                      class="custom-radio-button"
                    >
                      {{ labels.type[TYPE.DEPOSIT] }}
                      <font-awesome-icon
                        :icon="[
                          'far',
                          form.type === TYPE.DEPOSIT
                            ? 'circle-check'
                            : 'circle',
                        ]"
                        class="anticon flex-icon"
                      />
                    </a-radio-button>
                    <a-radio-button
                      v-if="
                        $route.query.type === TYPE.WITHDRAW ||
                        !$route.query.type
                      "
                      :value="TYPE.WITHDRAW"
                      class="custom-radio-button"
                      >{{ labels.type[TYPE.WITHDRAW] }}
                      <font-awesome-icon
                        :icon="[
                          'far',
                          form.type === TYPE.WITHDRAW
                            ? 'circle-check'
                            : 'circle',
                        ]"
                        class="anticon flex-icon"
                      />
                    </a-radio-button>
                    <a-radio-button
                      v-if="
                        $route.query.type === TYPE.TRANSFER ||
                        !$route.query.type
                      "
                      :value="TYPE.TRANSFER"
                      class="custom-radio-button"
                      >{{ labels.type[TYPE.TRANSFER] }}
                      <font-awesome-icon
                        :icon="[
                          'far',
                          form.type === TYPE.TRANSFER
                            ? 'circle-check'
                            : 'circle',
                        ]"
                        class="anticon flex-icon"
                      />
                    </a-radio-button>
                  </a-radio-group>
                </a-col>
              </a-row>

              <!-- NOTIFICATION -->
              <a-alert
                v-if="isWithdraw"
                :style="{ marginBottom: '15px', marginTop: '15px' }"
                message="Horarios de retiros:"
                type="info"
                closable
                show-icon
              >
                <template #description>
                  <span class="notification" v-html="labels.infoLabel" />
                </template>
              </a-alert>
              <a-alert
                v-if="isDeposit"
                :style="{ marginBottom: '15px', marginTop: '15px' }"
                message="Horarios de depositos:"
                type="info"
                closable
                show-icon
              >
                <template #description>
                  <span
                    class="notification"
                    v-html="
                      labels.depositInfoLabel.replace(
                        '{{deposit}}',
                        userDepositPercentageCommission
                          ? labels.depositCommissionInfoLabel.replace(
                              '{{variable}}',
                              userDepositPercentageCommission
                            )
                          : ''
                      )
                    "
                  />
                </template>
              </a-alert>

              <!-- TRANSACTION INFO -->
              <a-divider
                orientation="left"
                class="text-white"
                type="horizontal"
              >
                {{
                  isWithdraw || isTransfer
                    ? labels.transactionInfoLabel
                    : labels.paymentMethodInfoLabel
                }}
              </a-divider>

              <!-- DEPOSIT FORM -->
              <template v-if="isDeposit">
                <a-row type="flex" :gutter="[8, 8]">
                  <a-col
                    :xs="24"
                    :sm="24"
                    :md="isCreditCard && !isCreditCardEnabled ? 24 : 12"
                  >
                    <a-row type="flex" :gutter="[8, 8]">
                      <a-col :span="24">
                        <a-radio-group
                          :value="form.origin"
                          :size="isTabletDown ? 'small' : 'large'"
                          class="origin-radio-group"
                          @change="handleChangeOrigin"
                        >
                          <a-radio-button
                            :value="TYPE.TRANSFER"
                            class="origin-radio-button"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'building-columns']"
                              class="anticon"
                            />
                            <span>{{ labels.type[TYPE.BANK_TRANSFER] }}</span>
                          </a-radio-button>
                          <a-radio-button
                            :value="ORIGIN.TETHER"
                            class="origin-radio-button"
                          >
                            <img
                              :src="tetherLogo"
                              :alt="labels.type[ORIGIN.TETHER]"
                              class="tether-icon"
                            />
                            <span>{{ labels.type[ORIGIN.TETHER] }}</span>
                          </a-radio-button>
                          <a-radio-button
                            v-if="!isInBlackList"
                            :value="ORIGIN.CREDIT_CARD"
                            class="origin-radio-button"
                          >
                            <font-awesome-icon
                              :icon="['far', 'credit-card']"
                              class="anticon"
                            />
                            <span>{{ labels.type[ORIGIN.CREDIT_CARD] }}</span>
                          </a-radio-button>
                        </a-radio-group>
                      </a-col>
                      <a-col
                        v-if="
                          acceptedPolicies ||
                          [ORIGIN.TETHER, ORIGIN.CREDIT_CARD].includes(
                            form.origin
                          )
                        "
                        :xs="24"
                        :sm="24"
                        :md="isCreditCardEnabled || !isCreditCard ? 14 : 24"
                      >
                        <template v-if="isCreditCardEnabled || !isCreditCard">
                          <Input
                            type="number"
                            v-model="form.amount"
                            rules="required"
                            vid="amount"
                            :placeholder="labels.amount.placeholder"
                            size="large"
                            :allowClear="true"
                            :label="labels.amount.label"
                            :step="isCreditCard ? 200 : 100"
                            v-bind="
                              isWithdraw || isTransfer
                                ? { max: userBalance }
                                : {}
                            "
                            :min="
                              isSeller && !isCreditCard
                                ? 2000
                                : isCreditCard
                                ? minCreditCardAmount
                                : 100
                            "
                            @pressEnter.prevent="scrollIntoView($refs.upload)"
                          />
                          <div
                            class="frequent-amounts-container"
                            v-if="
                              [ORIGIN.TETHER, ORIGIN.TRANSFER].includes(
                                form.origin
                              )
                            "
                          >
                            <a-button
                              v-for="(amount, key) in isSeller
                                ? [2000, 3500, 5000, 6500]
                                : labels.btnAmounts"
                              :key="key"
                              class="btn-frequent-amount"
                              @click="setAmount(amount)"
                              >{{ amount }}</a-button
                            >
                          </div>
                          <div
                            class="frequent-amounts-container"
                            v-if="isCreditCard && !isInBlackList"
                          >
                            <a-button
                              v-for="(amount, key) in [
                                600, 1200, 2400, 3600, 5000,
                              ]"
                              :key="key"
                              class="btn-frequent-amount"
                              @click="setAmount(amount)"
                              >{{ amount }}</a-button
                            >
                          </div>
                        </template>
                        <template v-if="isCreditCard && !isCreditCardEnabled">
                          <a-alert
                            style="margin-top: 30px"
                            message="Importante!"
                            type="info"
                            show-icon
                          >
                            <span
                              slot="description"
                              v-html="
                                loadVariable(
                                  labels.isCreditCardEnabledMessage,
                                  {
                                    numero: whatsappLinkMessage(
                                      labels.whatsappMessage2
                                    ),
                                  }
                                )
                              "
                            />
                          </a-alert>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <div
                      v-if="form.origin === ORIGIN.TRANSFER"
                      class="deposit-tips-container"
                    >
                      <ol class="deposit-tips">
                        <li
                          v-for="(label, key) in labels.depositTips"
                          :key="key"
                          v-html="
                            key === 0
                              ? loadVariable(label, { whatsapp: whatsappLink })
                              : label
                          "
                        />
                      </ol>
                    </div>
                    <div
                      v-else-if="form.origin === ORIGIN.TETHER"
                      class="tether-tips-container"
                    >
                      <p
                        class="tether-tips"
                        v-html="labels.tetherTips.description"
                      />
                      <a-button
                        :disabled="!isTetherValid"
                        class="btn-tether"
                        type="link"
                        :href="tetherLink"
                        block
                        target="_blank"
                        ><span v-html="labels.tetherTips.linkLabel"
                      /></a-button>

                      <a-spin
                        :spinning="loadingQRCode"
                        :tip="labels.loadingQRCode"
                        class="tether-qr-code-container"
                      >
                        <div class="tether-qr-code">
                          <img
                            v-if="
                              !loadingQRCode && paymentBase64 && form.amount
                            "
                            class="tether-qr"
                            :src="paymentBase64"
                            alt="Payment QR Code"
                          />
                          <template
                            v-if="!loadingQRCode && paymentUri && form.amount"
                          >
                            <div class="tether-dir">
                              <h3>{{ labels.tetherDirTitle }}</h3>
                              <span>{{ paymentUri }}</span>
                            </div>
                            <div class="tether-footer">
                              <a-button
                                type="link"
                                @click="copyPaymentUriToClipboard"
                              >
                                <a-icon type="copy" />
                                {{ labels.copyToClipboard }}
                              </a-button>
                            </div>
                          </template>
                        </div>
                      </a-spin>
                    </div>
                    <div
                      v-else-if="form.origin === ORIGIN.CREDIT_CARD"
                      class="credit-card-tips-container"
                    >
                      <a-card
                        size="small"
                        class="card-credit-card-client"
                        :loading="loadingCreditCardClientLinks"
                        v-if="creditCardClient || loadingCreditCardClientLinks"
                        width="100%"
                      >
                        <div class="table-credit-card-client">
                          <div class="table-row-credit-card-client">
                            <span>{{
                              labels.creditCardClientTips.amount
                            }}</span>
                            <span>{{
                              (form?.amount || 0) | formatCurrency
                            }}</span>
                          </div>
                          <div class="table-row-credit-card-client">
                            <span>{{ labels.creditCardClientTips.fee }}</span>
                            <span>{{
                              ((form?.amount || 0) *
                                (creditCardClient?.percentage / 100))
                                | formatCurrency
                            }}</span>
                          </div>
                          <div class="table-row-credit-card-client total">
                            <span>{{ labels.creditCardClientTips.total }}</span>
                            <span>{{
                              ((form?.amount || 0) *
                                (1 + creditCardClient?.percentage / 100))
                                | formatCurrency
                            }}</span>
                          </div>
                        </div>
                      </a-card>
                    </div>
                  </a-col>
                </a-row>
                <!-- VOUCHER INFO -->
                <template v-if="form.origin === ORIGIN.TRANSFER">
                  <a-divider
                    orientation="left"
                    class="text-white"
                    type="horizontal"
                  >
                    {{ labels.vouchersLabel }}
                  </a-divider>
                  <a-row :gutter="[16, 16]" type="flex" justify="start">
                    <a-col :span="24">
                      <a-form-item :style="{ width: '100%' }">
                        <a-upload
                          ref="upload"
                          :disabled="submitting"
                          :multiple="true"
                          :accept="'image/*'"
                          :file-list="fileList"
                          list-type="picture"
                          :style="{ width: '100%' }"
                          :beforeUpload="beforeUpload"
                          :openFileDialogOnClick="true"
                          :remove="handleRemoveFile"
                          @preview="handlePreviewFile"
                          :customRequest="dummyRequest"
                        >
                          <a-button
                            size="large"
                            type="primary"
                            ghost
                            class="btn-upload"
                            :disabled="submitting"
                            :block="true"
                            :style="{ width: '100%' }"
                          >
                            <a-icon type="upload" />
                            {{ labels.voucher.label }}
                          </a-button>
                        </a-upload>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </template>
              </template>

              <!-- WITHDRAW FORM -->
              <template v-if="isWithdraw">
                <!-- TRANSACTION INFO -->
                <a-row type="flex" :gutter="[8, 8]">
                  <a-col :md="8" :sm="24" :xs="24">
                    <SelectComponent
                      v-model="form.origin"
                      :data="origins"
                      :label="labels.origin.label"
                      rules="required"
                      :placeholder="labels.origin.placeholder"
                      sortOptionsBy="label"
                      labelKey="label"
                      valueKey="value"
                      size="large"
                      dropdownClassName="custom-select"
                    />
                  </a-col>
                  <a-col :md="8" :sm="24" :xs="24">
                    <SelectComponent
                      v-model="form.currency"
                      :data="currencies"
                      :label="labels.currency.label"
                      rules="required"
                      :placeholder="labels.currency.placeholder"
                      sortOptionsBy="label"
                      labelKey="label"
                      valueKey="value"
                      size="large"
                      dropdownClassName="custom-select"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="8">
                    <Input
                      type="number"
                      v-model="form.amount"
                      rules="required"
                      vid="amount"
                      :placeholder="labels.amount.placeholder"
                      size="large"
                      :allowClear="true"
                      :label="labels.amount.label"
                      v-bind="
                        (isWithdraw || isTransfer) && { max: userBalance }
                      "
                      :min="isWithdraw ? 200 : 1"
                    />
                    <div class="frequent-amounts-container">
                      <a-button
                        v-for="(amount, key) in labels.btnAmounts"
                        :key="key"
                        class="btn-frequent-amount"
                        @click="setAmount(amount)"
                        >{{ amount }}</a-button
                      >
                    </div>
                  </a-col>
                </a-row>

                <!-- BANK ACCOUNT INFO -->
                <a-divider
                  orientation="left"
                  class="text-white"
                  type="horizontal"
                >
                  {{ labels.bankInfoLabel }}
                </a-divider>
                <validation-provider
                  vid="account"
                  name="Cuenta bancaria"
                  rules="required"
                >
                  <user-bank-account-component
                    slot-scope="{ errors }"
                    v-model="bankAccount"
                    :labels="labels"
                    :error="errors[0]"
                  />
                </validation-provider>
              </template>

              <!-- TRANSFER FORM -->
              <template v-if="isTransfer">
                <a-row type="flex" justify="space-between" :gutter="[8, 8]">
                  <a-col :md="8" :sm="24" :xs="24">
                    <Input
                      v-model="form.destination_account_phone"
                      rules="required|phoneNumber"
                      vid="destinationAccountPhone"
                      :placeholder="labels.destinationAccountPhone.placeholder"
                      size="large"
                      :allowClear="true"
                      :label="labels.destinationAccountPhone.label"
                      v-themask="['(###) ###-####']"
                    />
                    <Input
                      type="number"
                      v-model="form.amount"
                      rules="required"
                      vid="amount"
                      :placeholder="labels.amount.placeholder"
                      size="large"
                      :allowClear="true"
                      :label="labels.amount.label"
                      v-bind="
                        (isWithdraw || isTransfer) && { max: userBalance }
                      "
                      :min="1"
                    />
                    <div class="frequent-amounts-container">
                      <a-button
                        v-for="(amount, key) in labels.btnAmounts"
                        :key="key"
                        class="btn-frequent-amount"
                        @click="setAmount(amount)"
                        >{{ amount }}</a-button
                      >
                    </div>
                  </a-col>
                  <a-col
                    v-if="form.destination_account_phone && form.amount"
                    :md="8"
                    :sm="24"
                    :xs="24"
                  >
                    <div class="transfer-total">
                      <h4 class="transfer-total-title">
                        {{ labels.transfer.title }}
                      </h4>
                      <div class="transfer-total-details-item">
                        <span class="label">{{ labels.transfer.amount }}</span>
                        <span class="value">{{
                          form.amount | formatNumber
                        }}</span>
                      </div>
                      <div class="transfer-total-details-item">
                        <span class="label"
                          >{{ labels.transfer.charges
                          }}{{ transferFee ? ` (${transferFee}%)` : "" }}</span
                        >
                        <span class="value">{{
                          (form.amount * (transferFee / 100)) | formatNumber
                        }}</span>
                      </div>
                      <div class="transfer-total-details-item bordered">
                        <span class="label">{{ labels.transfer.total }}</span>
                        <span class="value">{{
                          (form.amount + form.amount * (transferFee / 100))
                            | formatNumber
                        }}</span>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </template>

              <a-row
                v-if="![ORIGIN.TETHER].includes(form.origin)"
                type="flex"
                justify="center"
                :style="{ alignItems: 'center' }"
                :gutter="[24, 24]"
              >
                <a-col :span="24">
                  <a-form-item style="text-align: center">
                    <a-button
                      v-if="!isCreditCard"
                      type="primary"
                      :loading="submitting"
                      :disabled="
                        submitting ||
                        (isDeposit &&
                          !acceptedPolicies &&
                          form.origin !== ORIGIN.TRANSFER)
                      "
                      size="large"
                      class="btn-submit"
                      @click="
                        validate().then(
                          isTransfer ? handleConfirmPassword : handleSubmit
                        )
                      "
                    >
                      <font-awesome-icon
                        v-if="!submitting"
                        :icon="['fas', 'paper-plane']"
                        class="anticon"
                      />
                      {{ labels.submit }}
                    </a-button>
                    <a-button
                      v-if="isCreditCard && isCreditCardEnabled"
                      type="default"
                      size="large"
                      @click="handleCreditCardPayment"
                      :disabled="!form?.amount || submitting"
                    >
                      <font-awesome-icon
                        v-if="!submitting"
                        :icon="['far', 'credit-card']"
                        class="anticon"
                      />
                      {{ labels.pay }}
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </ValidationObserver>
        </a-card>
      </div>
    </a-spin>
    <!-- NOTIFICATION MODAL -->
    <a-modal
      v-if="notification"
      :visible="notification.visible"
      :closable="true"
      :footer="null"
      centered
      class="notification-modal"
      :loading="this.submitting"
      @cancel="resetNotificationModal"
    >
      <div class="notification-modal-title">
        <a-icon :type="notification.icon" class="notification-modal-icon" />
        <span>{{ notification.title }}</span>
      </div>
      <p
        v-if="notification.message"
        class="notification-modal-description"
        v-html="notification.message"
      />
      <a-button
        v-if="
          notification.visible &&
          notification.title !== labels.creditCardClientTips.process.redirecting
        "
        type="primary"
        @click="handleRedirectPaymentClient"
        class="notification-modal-btn"
        ghost
        block
        :loading="this.submitting"
      >
        {{ labels.creditCardClientTips.process.btnProcess }}
      </a-button>
    </a-modal>
  </a-layout>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import SelectComponent from "@/components/core/SelectPagination";
import Input from "@/components/core/VeeValidateForm/Input";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import { VueMaskFilter } from "v-mask";
import { mask } from "vue-the-mask";
import accounting from "accounting";
import { DATE_FORMAT, hasRoles, roles } from "../../../utils/utils";
import tetherLogo from "@/assets/images/tether-usdt.png";
import CryptAPI from "@/utils/cryptapi";
import UserBankAccountComponent from "@/components/UserBankAccountComponent";

export const TYPE = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  TRANSFER: "TRANSFER",
  BANK_TRANSFER: "BANK_TRANSFER",
};

export const ORIGIN = {
  TRANSFER: "TRANSFER",
  TETHER: "TETHER",
  CREDIT_CARD: "CREDIT_CARD",
};

export const CURRENCY = {
  DOP: "DOP",
  USD: "USD",
  BTC: "BTC",
};

export const ACCOUNT_TYPE = {
  SAVINGS: "SAVINGS",
  CHECKING: "CHECKING",
};

const initialState = {
  type: TYPE.DEPOSIT,
  origin: undefined,
  currency: {
    value: CURRENCY.DOP,
    label: labels.transactionForm.currency[CURRENCY.DOP],
  },
  amount: undefined,
  bank: undefined,
  account: undefined,

  voucher: [],
  reference: undefined,

  account_type: ACCOUNT_TYPE.SAVINGS,
  identification_card: undefined,
  destination_account_phone: undefined,
  destination_user: undefined,
  addToMyAccounts: false,
  password: undefined,
};

const defaultNotification = {
  visible: false,
  title: "",
  message: "",
  icon: "",
  theme: "",
};

export default {
  components: {
    TitleBreadcrumb,
    ValidationObserver,
    ValidationProvider,
    Input,
    UserBankAccountComponent,
    SelectComponent,
  },
  name: "TransactionForm",
  directives: { themask: mask },
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.transactionForm.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.transactionForm.subtitle,
      },
    ],
  },
  data() {
    return {
      labels: {
        ...labels.transactionForm,
        appName: labels.appName,
      },
      transaction: null,
      bankAccount: null,
      form: _.cloneDeep(initialState),
      TYPE,
      ORIGIN,
      currencies: Object.values(CURRENCY).map((currency) => ({
        value: currency,
        label: labels.transactionForm.currency[currency],
      })),
      origins: Object.values(ORIGIN)
        .filter((ori) => ori === ORIGIN.TRANSFER)
        .map((origin) => ({
          value: origin,
          label: labels.transactionForm.origin[origin],
        })),
      fileList: [],
      submitting: false,
      tetherLogo,
      ca: null,
      paymentBase64: null,
      paymentUri: null,
      loadingQRCode: false,
      transactionToken: null,
      creditCardClient: null,
      creditCardClientLinks: [],
      loadingCreditCardClientLinks: false,
      CURRENCY,
      notification: _.cloneDeep(defaultNotification),
      acceptedPolicies: false,
      transferFee: 0,
    };
  },
  async created() {
    await this.fetchCryptapiConfig();
    await this.loadTransferFee();
    if (this.$route?.query?.type) {
      this.form.type = this.$route.query?.type;
    }
    this.$nextTick(async () => {
      await this.$store.dispatch("systemConfig/fetchWhatsappConfig");
    });
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    ...mapState("clientAuth", ["user"]),
    ...mapGetters("clientAuth", [
      "getLoggedUser",
      "isInBlackList",
      "isCreditCardEnabled",
    ]),
    ...mapGetters({
      cryptapi: "systemConfig/getCryptApiConfig",
    }),
    ...mapState({
      whatsapp: (state) => state.systemConfig.whatsapp,
    }),
    whatsappLink() {
      const a = document.createElement("a");
      a.href = `https://wa.me/${this.whatsapp}?text=${encodeURIComponent(
        this.labels.whatsappMessage
      )}`;
      a.target = "_blank";
      a.classList.add("whatsapp-link");
      a.style.color = "inherit";
      const phone = document.createElement("span");
      phone.innerHTML = this.$options.filters.mask(
        this.whatsapp,
        "+# (###) ###-####"
      );
      a.appendChild(phone);
      return a.outerHTML;
    },
    isTetherValid() {
      const { whatsapp } = this.getLoggedUser;
      return this.form.origin === ORIGIN.TETHER && whatsapp && this.form.amount;
    },
    isCreditCardClientValid() {
      return (
        this.form.origin === ORIGIN.CREDIT_CARD && this.creditCardClient?.link
      );
    },
    tetherLink() {
      const { whatsapp } = this.getLoggedUser;
      return this.isTetherValid && this.cryptapi?.solucredito_mifosx_api_url
        ? `${
            this.cryptapi?.solucredito_mifosx_api_url
          }?phone=${whatsapp}&amount=${this.getConversion(
            this.form.amount
          )}&verified=true&token=${this.transactionToken}`
        : "javascript:;";
    },
    creditCardClientLink() {
      const percentage = this.creditCardClient?.percentage || 0;
      const amount = (this.form.amount || 0) * (1 + percentage / 100);
      return this.isCreditCardClientValid
        ? this.creditCardClient.link
            .replace("{amount}", amount)
            .replace("{percentage}", percentage)
        : null;
    },
    isMobile() {
      return this.screenWidth < 592;
    },
    isTabletDown() {
      return this.screenWidth < 768;
    },
    isWithdraw() {
      return this.form.type === TYPE.WITHDRAW;
    },
    isDeposit() {
      return this.form.type === TYPE.DEPOSIT;
    },
    isTransfer() {
      return this.form.type === TYPE.TRANSFER;
    },
    isCreditCard() {
      return this.form.origin === ORIGIN.CREDIT_CARD;
    },
    isSeller() {
      const { accessRoles = [] } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.client.ROLE_SELLER]);
    },
    userBalance() {
      return this.user?.balance || 0;
    },
    minCreditCardAmount() {
      if (!this.cryptapi?.exchange_rate_usd_to_dop) return 600;
      const amount =
        parseFloat(this.cryptapi.exchange_rate_usd_to_dop || "0") * 10;
      return amount < 600 ? 600 : amount;
    },
    userDepositPercentageCommission() {
      return (
        this.getLoggedUser?.commissionProfile?.schema?.find(
          (c) => c.type === "USER_DEPOSIT_COMMISSION"
        )?.percentage || null
      );
    },
  },
  methods: {
    ...mapActions("transactions", [
      "requestTransaction",
      "generateTransactionToken",
      "getSkyChainPaymentLink",
    ]),
    ...mapActions(["fetchAccountByPhone"]),
    ...mapActions("systemConfig", [
      "fetchCryptapiConfig",
      "getCreditCardPaymentClientLinks",
      "getCraueConfigByNameClient",
    ]),
    whatsappLinkMessage(message = this.labels.whatsappMessage) {
      const a = document.createElement("a");
      a.href = `https://wa.me/${this.whatsapp}?text=${encodeURIComponent(
        message
      )}`;
      a.target = "_blank";
      a.classList.add("whatsapp-link");
      a.style.color = "inherit";
      const phone = document.createElement("span");
      phone.innerHTML = this.$options.filters.mask(
        this.whatsapp,
        "+# (###) ###-####"
      );
      a.appendChild(phone);
      return a.outerHTML;
    },
    scrollIntoView(ref) {
      if (ref) {
        ref.$el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    setAmount(amount) {
      this.form.amount = amount;
    },
    setCreditCardClient(client) {
      this.creditCardClient = client;
      this.form.amount = client.amount;
    },
    loadVariable(label, variables = {}) {
      return label.replace(/{{(\w+)}}/g, (match, p1) => variables[p1] || match);
    },
    isValidPhone(phone) {
      return phone && phone.replace(/\D/g, "").length === 10;
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },
    async handleSubmit() {
      if (this.submitting) return;
      try {
        // VALIDATE VOUCHER WHEN DEPOSIT
        if (this.isDeposit && !this.form.voucher.length) {
          this.$error({
            class: "error-modal",
            title: this.labels.formError,
            content: this.labels.voucher.required,
          });
          return;
        }

        // VALIDATE PASSWORD WHEN TRANSFER
        if (this.isTransfer && !this.form.password) {
          this.$error({
            class: "error-modal",
            title: this.labels.formError,
            content: this.labels.password.required,
          });
          return;
        }

        this.submitting = true;
        const formData = new FormData();
        Object.keys(this.form)
          .filter((key) => !!this.form[key])
          .forEach((key) => {
            if (key === "voucher") {
              this.form.voucher.forEach((file) => {
                formData.append("voucher[]", file, file.name);
              });
            } else {
              if (
                ["origin", "currency", "account_type", "bank"].includes(key) &&
                this.form[key]?.value
              ) {
                formData.append(key, this.form[key].value);
              } else if (["destination_user"].includes(key) && this.form[key]) {
                Object.keys(this.form[key]).forEach((subKey) => {
                  formData.append(`${key}[${subKey}]`, this.form[key][subKey]);
                });
              } else if (["password"].includes(key)) {
                if (this.isTransfer && this.form[key]) {
                  formData.append(key, this.form[key]);
                }
              } else {
                formData.append(key, this.form[key]);
              }
            }
          });
        await this.requestTransaction(formData);
        const refreshSession = [TYPE.TRANSFER, TYPE.WITHDRAW].includes(
          this.form.type
        );
        const isTransfer = this.form.type === TYPE.TRANSFER;
        this.$notification["success"]({
          message: isTransfer
            ? this.labels.transferSuccess
            : this.labels.success,
        });
        this.form = _.cloneDeep(initialState);
        if (refreshSession) this.checkSession();
        this.$router.push({ name: "transaction-list" });
      } catch (error) {
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          this.$router.push({ name: "login" });
        } else if (error.isAxiosError && error.response.status === 400) {
          this.$notification["error"]({
            message: error.response?.data?.message || error.response?.message,
            description: (h) => {
              return h(
                "ul",
                error.response?.data?.additional_errors?.length
                  ? error.response?.data?.additional_errors?.map((error) => {
                      return h("li", error);
                    })
                  : h("li", error.response?.data?.message)
              );
            },
          });
        } else {
          this.$notification["error"]({
            message:
              error?.response?.data?.message ||
              error?.response?.message ||
              error.message,
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    handlePreviewFile(file) {
      // CREATE IMG AND OPEN IN NEW TAB
      const img = document.createElement("img");
      img.src = file.url || file.thumbUrl;
      img.style.maxWidth = "520px";
      const w = window.open("", "_blank", "width=520,height=auto");
      w?.document.write(img.outerHTML);
    },
    handleRemoveFile(file) {
      if (file?.uid) {
        this.form.voucher = this.form.voucher.filter(
          (item) => item.uid !== file.uid
        );
        this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
      }
    },
    async beforeUpload(file) {
      const fileBase64 = await this.toBase64(file);
      this.fileList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        url: fileBase64,
        thumbUrl: fileBase64,
      });
      this.form.voucher.push(file);
      return true;
    },
    dummyRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    async handleRequestDestinationAccountInfo() {
      try {
        if (
          this.isValidPhone(this.form.destination_account_phone) &&
          this.form.destination_account_phone?.replace(/\D/g, "") !==
            this.form.destination_user?.whatsapp
        ) {
          const { data } = await this.fetchAccountByPhone(
            this.form.destination_account_phone
          );
          Object.assign(this.form, {
            destination_user: _.cloneDeep(data),
          });
        }
      } catch (error) {
        this.$notification["error"]({
          message: this.labels.accountNotFound,
          description: this.labels.accountNotFoundDescription,
        });
      }
    },
    async handleRequestQRCode() {
      try {
        this.loadingQRCode = true;
        await this.ca.getAddress();
        const qrCode = await this.ca.getQrcode(
          this.getConversion(this.form.amount),
          parseInt(this.cryptapi.cryptapi_size || 100)
        );
        this.paymentBase64 = `data:image/png;base64,${qrCode.qr_code}`;
        this.paymentUri = qrCode.payment_uri;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingQRCode = false;
      }
    },
    copyPaymentUriToClipboard() {
      const el = document.createElement("textarea");
      el.value = this.paymentUri;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$notification["success"]({
        message: this.labels.copiedToClipboard,
      });
    },
    async generateToken() {
      try {
        const response = await this.generateTransactionToken();
        this.transactionToken = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async generateCrypApiConfig(config) {
      // NOTE: GENERATE TOKEN FOR TRANSACTION
      await this.generateToken();

      const callbackUri = config.cryptapi_callback_uri.replace(
        "{token}",
        this.transactionToken
      );

      const cryptapiParams = {
        post: 1,
        email: config.cryptapi_notify_email,
        callback: callbackUri,
        address: config.cryptapi_address,
      };

      this.ca = new CryptAPI(
        config.cryptapi_ticker,
        config.cryptapi_address,
        callbackUri,
        {},
        cryptapiParams
      );
    },
    getConversion(amount) {
      if (!amount) return 0;

      amount = this.cryptapi?.exchange_rate_usd_to_dop
        ? amount / parseFloat(this.cryptapi.exchange_rate_usd_to_dop || "0")
        : amount;

      return parseFloat(accounting.formatNumber(amount, 2));
    },
    async handleGetCreditCardPaymentClientLinks() {
      try {
        this.loadingCreditCardClientLinks = true;
        const { data } = await this.getCreditCardPaymentClientLinks();
        this.creditCardClientLinks = data;
      } catch (error) {
        this.creditCardClientLinks = [];
        this.$notification["error"]({
          message:
            error.response.message || this.labels.creditCardClientTips.error,
        });
      } finally {
        this.loadingCreditCardClientLinks = false;
      }
    },
    openConfirmCreditCardPaymentClient() {
      this.notification = {
        visible: true,
        title: this.labels.creditCardClientTips.process.title,
        message: this.labels.creditCardClientTips.process.description,
        icon: "check-circle",
      };
    },
    resetNotificationModal() {
      this.notification = _.cloneDeep(defaultNotification);
    },
    async handleRedirectPaymentClient() {
      try {
        this.submitting = true;

        const { data } = await this.getSkyChainPaymentLink({
          amount: this.form.amount,
        });

        this.notification = {
          ..._.cloneDeep(defaultNotification),
          visible: true,
          title: this.labels.creditCardClientTips.process.redirecting,
          icon: "loading",
        };

        setTimeout(() => {
          this.resetNotificationModal();
          this.form = _.cloneDeep(initialState);
          window.location.href = data.url;
        }, 1500);
      } catch (error) {
        console.error(error);
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          this.$router.push({ name: "login" });
        } else if (error.isAxiosError && error.response.status === 400) {
          this.$notification["error"]({
            message: error.response?.data?.message || error.response?.message,
            description: (h) => {
              return h(
                "ul",
                error.response?.data?.additional_errors?.length
                  ? error.response?.data?.additional_errors?.map((error) => {
                      return h("li", error);
                    })
                  : h("li", error.response?.data?.message)
              );
            },
          });
        } else {
          this.$notification["error"]({
            message:
              error?.response?.data?.message ||
              error?.response?.message ||
              error.message,
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    async handleCreditCardPayment() {
      try {
        if (!this.form.amount) {
          this.$notification["error"]({
            message: this.labels.creditCardClientTips.requiredAmount,
          });
          return;
        }
        this.openConfirmCreditCardPaymentClient();
      } catch (error) {
        console.error(error);
      }
    },
    confirmPolicy(value) {
      this.$confirm({
        title: this.labels.confirmPolicy.title,
        content: (
          <p class={"text-content"}> {this.labels.confirmPolicy.content} </p>
        ),
        okText: this.labels.confirmPolicy.okText,
        iconType: "warning",
        class: "confirm-policy",
        closable: false,
        keyboard: false,
        cancelButtonProps: {
          style: {
            display: "none",
          },
        },
        autoFocusButton: "ok",
        width: this.isMobile ? "100%" : 500,
        onOk: () => {
          this.acceptedPolicies = true;
          this.form.origin = value;
        },
        onCancel: () => {
          this.acceptedPolicies = false;
          this.form.origin = undefined;
        },
      });
    },
    handleChangeOrigin(event) {
      event.preventDefault();
      this.form.origin = undefined;
      this.acceptedPolicies = false;
      const { value } = event.target;
      if (value === TYPE.TRANSFER) {
        this.confirmPolicy(value);
      } else {
        this.form.origin = value;
      }
    },
    async loadTransferFee() {
      try {
        const { data } = await this.getCraueConfigByNameClient(
          "percentage_fees_per_transfer"
        );
        this.transferFee = parseFloat((data?.value || "0").replace(/\D/, ""));
      } catch (error) {
        this.transferFee = 0;
      }
    },
    handleConfirmPassword() {
      this.form.password = undefined;
      this.$confirm({
        title: this.labels.confirmPassword.title,
        class: "confirm-transfer-modal",
        content: (h) =>
          h(
            "div",
            {
              class: "confirm-transfer-content",
            },
            [
              h("p", this.labels.confirmPassword.subtitle),
              h(
                "a-form-item",
                {
                  props: {
                    label: this.labels.password.label,
                    labelCol: { span: 24 },
                  },
                },
                [
                  h("a-input-password", {
                    props: {
                      placeholder: this.labels.password.placeholder,
                      value: this.form.password,
                      allowClear: true,
                    },
                    on: {
                      input: (event) => {
                        this.form.password = event.target.value;
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
        okText: this.labels.confirmPassword.confirmButtonText,
        okType: "primary",
        icon: "info-circle",
        cancelText: this.labels.confirmPassword.cancelButtonText,
        onOk: async () => {
          this.handleSubmit(this.form);
        },
        onCancel: () => {
          this.form.password = undefined;
        },
      });
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format(DATE_FORMAT.MOMENT_DATE_TIME);
    },
    mask: VueMaskFilter,
    formatCurrency(value) {
      return accounting.formatMoney(value, "RD$ ", 2, ",", ".");
    },
    formatNumber(value) {
      return accounting.formatNumber(value, 0, ",", ".");
    },
  },
  beforeDestroy() {
    this.form = null;
    this.submitting = false;
  },
  watch: {
    "$route.query.type": {
      handler: function (val, oldVal) {
        if (oldVal !== val) {
          this.form = _.cloneDeep(initialState);
          this.form.type = val;
        }
      },
      deep: true,
    },
    "$route.query.message": {
      handler: function (val, oldVal) {
        if (oldVal !== val) {
          const message =
            val?.toLowerCase() === "success"
              ? this.labels.creditCardClientTips.success
              : val?.toLowerCase() === "cancelled"
              ? this.labels.creditCardClientTips.cancelled
              : null;

          if (message) {
            this.$success({
              class: "success-credit-card-client",
              title:
                val?.toLowerCase() === "success"
                  ? this.labels.creditCardClientTips.successTitle
                  : this.labels.creditCardClientTips.cancelledTitle,
              content: message,
              onOk: () => {
                if (val?.toLowerCase() === "success")
                  this.$router.push({ name: "buy" });
              },
              onCancel: () => {
                if (val?.toLowerCase() === "success")
                  this.$router.push({ name: "buy" });
              },
              okText: this.labels.creditCardClientTips.okText,
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "form.type": {
      handler: function (val, oldVal) {
        if (oldVal !== val) {
          if (val === TYPE.DEPOSIT) {
            this.form.account_type = [];
            this.form.email_holder = null;
            this.form.identification_card = null;
            this.form.account_holder = null;
            this.form.phone_holder = null;
          } else {
            this.form.voucher = [];
            this.form.reference = null;
          }
        }
      },
      deep: true,
    },
    "form.origin": {
      handler: function (val, oldVal) {
        if (oldVal !== val) {
          if (val === ORIGIN.TETHER) {
            this.generateToken();
            if (this.form.amount && this.ca) {
              this.handleRequestQRCode(this.form.amount);
            }
          }
          // else if (val === ORIGIN.CREDIT_CARD) {
          //   this.handleGetCreditCardPaymentClientLinks();
          // }
          else {
            this.creditCardClient = null;
          }
        }
      },
      deep: true,
      inmediate: true,
    },
    cryptapi: {
      handler: function (config) {
        if (config) {
          this.generateCrypApiConfig(config);
        }
      },
      deep: true,
      inmediate: true,
    },
    "form.amount": {
      handler: function (val, oldVal) {
        if (
          val &&
          oldVal !== val &&
          val > 0 &&
          this.form.origin === ORIGIN.TETHER
        ) {
          if (this.ca) {
            this.handleRequestQRCode(val);
          }
        }
      },
      deep: true,
    },
    bankAccount: {
      handler: function (val, oldVal) {
        if (val && !_.isEqual(val, oldVal)) {
          this.form.bank = val.bank;
          this.form.account = val.account;
          this.form.account_type = val.account_type;
          this.form.identification_card = val.identification_card;
          this.form.addToMyAccounts = val.save;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.transaction-form {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 16px;
    color: color(--white);
  }
  .text-white {
    color: color(--white);
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
    .btn-print {
      color: color(c-hover);
      border-color: color(c-hover);
      background-color: color(c-secondary);
      transition: all 0.5s ease;
      &:hover {
        color: color(c-primary);
        border-color: color(c-hover);
        background-color: color(c-hover);
        transition: all 0.5s ease;
      }
    }
  }
  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    &-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      line-height: 1.2;
      &.small {
        font-size: 1rem;
      }
    }
    &-subtitle {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 20px;
      color: color(--white);
      text-align: center;
    }
    &-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }
    &-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dotted color(--white);
    }
    &-total-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }
    &-total-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dashed color(--white);
    }
  }
  .btn-submit {
    background-color: color(c-primary);
    border-color: color(c-hover);
    color: color(--white);
    transition: all 0.5s ease;
    margin-top: 20px;
    &:hover {
      background-color: color(c-hover);
      border-color: color(c-hover);
      color: color(--white);
      transition: all 0.5s ease;
    }
  }
  .ant-table {
    &:hover {
      transition: 0.5s ease;
      background: unset;
    }
    tr.ant-table-row:hover {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.25) !important;
    }
    tr:nth-child(2n) {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.2) !important;
    }
    tr.table-row-disabled,
    tr.table-row-disabled:hover {
      color: color(c-primary);
      background: color(danger-light) !important;
      font-weight: 600;
    }
    tr.table-row-disabled-hover:hover,
    tr.table-row-disabled-hover {
      background: none !important;
      font-weight: 700;
      font-size: 16px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      transition: 0.5s ease;
      background: none !important;
    }
    &-tbody,
    &-thead {
      color: color(--white) !important;
      tr {
        th {
          color: color(--white) !important;
        }
      }
    }
    &-footer {
      background: transparent;
    }
    @media screen and (max-width: $screen-mobile) {
      &-small {
        border: none;
      }
      &-body {
        margin: 0 1px;
      }
    }
  }
  .custom-radio-button {
    background-color: color(c-secondary);
    border-color: color(c-hover);
    color: color(c-hover);
    min-width: 180px;
    height: 80px;
    text-align: center;
    position: relative;
    padding: 18px 36px;
    font-size: 22px;
    font-weight: 500;
    .flex-icon {
      position: absolute;
      right: 7px;
      top: 18px;
      transform: translateY(-50%);
      font-size: 1.5rem;
    }
    &:hover {
      background-color: color(c-secondary);
      border-color: color(c-hover);
      color: color(c-hover);
    }
    &:focus {
      background-color: color(c-secondary);
      border-color: color(c-hover);
      color: color(c-hover);
    }
    &.ant-radio-button-wrapper-checked {
      background-color: color(c-hover);
      border-color: color(c-hover);
      color: color(c-secondary);
    }
    @media screen and (max-width: $screen-mobile) {
      font-size: 14px;
      height: 32px;
      min-width: 80px;
      padding: 5px 14px;
      transition: all 0.5s ease;
      .flex-icon {
        display: none;
      }
    }
  }
  .origin-radio-group {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    .origin-radio-button {
      background-color: color(c-secondary);
      border-color: color(c-hover);
      color: color(c-hover);
      width: 136px;
      min-height: 134px;
      text-align: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      padding: 12px;
      .tether-icon {
        width: 60px;
        display: block;
        margin: auto;
        margin-bottom: 10px;
        padding: 2px;
        background-color: color(--white);
        border-radius: 50%;
      }
      .anticon {
        font-size: 56px;
        display: block;
        margin: auto;
        margin-bottom: 10px;
        text-align: center;
      }
      &.ant-radio-button-wrapper-checked {
        background-color: color(c-hover);
        border-color: color(c-hover);
        color: color(c-secondary);
      }
    }
  }
  .frequent-amounts-container {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    max-width: 100%;
    flex-wrap: wrap;
    .btn-frequent-amount {
      background-color: color(c-secondary);
      border-color: color(c-hover);
      color: color(c-hover);
      text-align: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      min-width: 65px;
      &:hover {
        background-color: color(c-hover);
        border-color: color(c-hover);
        color: color(c-secondary);
      }
    }
  }
  .tether-tips-container,
  .deposit-tips-container {
    width: 100%;
    margin: 0;
    padding: 0;
    color: color(c-hover);
    font-size: 18px;
    .deposit-tips {
      margin-bottom: 0 !important;
      line-height: 1.5;
      li {
        margin-top: 10px;
      }
    }
    .tether-tips {
      color: color(c-hover);
      font-size: 18px;
    }
    .btn-tether {
      color: color(c-hover);
      font-size: 22px;
      font-weight: 500;
      &[disabled] {
        color: color(--disabled) !important;
        cursor: not-allowed !important;
      }
    }
  }
  .ant-form label {
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input-number,
  .ant-input {
    background-color: color(c-primary);
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
    .ant-input-clear-icon,
    .ant-input-textarea-clear-icon,
    .ant-input-password-icon {
      color: color(--white);
    }
  }
  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }
  .ant-select {
    .ant-select-selection--single {
      color: color(--white);
      background-color: color(c-primary);
      border: 1px solid #d9d9d9;
      &:active,
      &:focus,
      &:hover {
        border-color: color(c-hover);
      }
      .anticon-down {
        color: color(--white);
      }
    }
  }
  .tether {
    &-qr {
      width: 100px !important;
    }
    &-footer {
      grid-column: 1/3;
      width: 100%;
      text-align: right;
      .ant-btn-link {
        color: color(--white);
      }
    }
    &-dir {
      h3 {
        color: color(--white);
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 2px;
        text-align: left;
      }
    }
    &-qr-code {
      width: 100%;
      max-width: 100%;
      min-width: 421px;
      min-height: 132px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-template-rows: auto auto;
      column-gap: 15px;
      overflow-wrap: anywhere;
      @media screen and (max-width: $screen-mobile) {
        min-width: 100%;
        row-gap: 15px;
      }
      &-container {
        margin-top: 30px;
        .ant-spin-text {
          color: color(--white) !important;
          text-shadow: none !important;
        }
      }
    }
  }
  .notification {
    font-size: 16px;
    .note {
      margin-bottom: 0;
      & > span {
        margin-left: 25px;
        display: block;
        margin-top: 10px;
      }
    }
  }
  .card-credit-card-client {
    background-color: transparent;
    color: color(--white);
    .table-credit-card-client {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      .table-row-credit-card-client {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        span:first-child {
          font-weight: 500;
        }
        &.total {
          border-top: 1px solid color(--white);
        }
      }
    }
  }
  .transfer-total {
    min-width: 200px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: white;
    margin-top: 10px;
    &-title {
      width: 100%;
      justify-self: flex-start;
      text-align: left;
      color: white;
      font-size: 18px;
      text-transform: uppercase;
    }
    &-details-item {
      display: flex;
      justify-content: space-between;
      &:last-child {
        padding-top: 2px;
        border-top: 1px double whitesmoke;
      }
      .label {
        text-align: left;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
      }
      .value {
        text-align: right;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}
.custom-select {
  background-color: color(c-primary) !important;
  .ant-select-dropdown-menu-item {
    color: color(--white) !important;
    &:hover {
      background-color: color(c-secondary) !important;
    }
    &-selected,
    &-active {
      background-color: color(c-secondary) !important;
    }
  }
}
.btn-upload {
  color: color(c-hover) !important;
  border-color: color(c-hover) !important;
  background-color: color(c-primary) !important;
  transition: all 0.5s ease;
}
.anticon-delete {
  color: color(danger) !important;
  font-size: 20px;
  margin-right: 10px;
}
.ant-upload {
  width: 100%;
}
.ant-upload-list-item-card-actions.picture {
  top: 50%;
  transform: translateY(-50%);
}
.ant-spin-dot-item {
  background-color: color(c-hover) !important;
}

.notification-modal {
  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 16px;
    color: color(c-primary);
    line-height: 1.2;
    &.small {
      font-size: 1rem;
    }
  }
  &-icon {
    font-size: 2rem;
    margin-right: 10px;
    color: color(c-primary);
  }
  &-description {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &-btn {
    background-color: color(c-primary) !important;
    border-color: color(c-hover) !important;
    color: color(c-hover) !important;
    transition: all 0.5s ease;
    margin-top: 20px;
    &:hover {
      background-color: color(c-hover) !important;
      border-color: color(c-hover) !important;
      color: color(c-primary) !important;
      transition: all 0.5s ease;
    }
  }
}
.success-credit-card-client {
  .ant-btn-primary {
    background-color: color(c-primary) !important;
    border-color: color(c-hover) !important;
    color: color(c-hover) !important;
    transition: all 0.5s ease;
    &:hover {
      background-color: color(c-hover) !important;
      border-color: color(c-hover) !important;
      color: color(c-primary) !important;
      transition: all 0.5s ease;
    }
  }
}
.confirm-transfer-modal,
.confirm-policy,
.error-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      color: color(--white) !important;
      .link {
        color: color(c-hover);
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        margin-left: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
      .error-list-item {
        font-size: 0.95rem;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: color(c-secondary);
        color: color(c-hover) !important;
        border-color: color(c-hover);
        &:hover,
        &:focus,
        &:active {
          background-color: color(c-secondary);
          color: color(c-hover) !important;
          border-color: color(c-hover);
        }
        &.ant-btn-primary {
          background-color: color(c-hover);
          color: color(c-primary) !important;
          border-color: color(c-hover);
          &:hover {
            background-color: color(c-hover);
            color: color(c-primary) !important;
            border-color: color(c-hover);
          }
        }
      }
    }
    .ant-form-item-label > label {
      color: white !important;
    }
  }
}
.confirm-policy .ant-modal-confirm-content {
  margin-left: 0 !important;
  .text-content {
    margin-bottom: 0;
    font-size: 1rem !important;
    margin-top: 10px;
  }
}
</style>
