<template>
  <div class="user-bank-accounts">
    <!-- LIST OF BANK ACCOUNTS CARDS -->
    <div class="bank-accounts">
      <a-card
        v-for="(account, index) in dataSource"
        :key="index"
        size="small"
        @click="handleSelected({ account, index })"
        class="card-account-item"
        :class="{ selected: index === selected?.index }"
        :loading="loading"
      >
        <div class="account-container">
          <div class="delete-btn">
            <a-icon type="delete" @click.stop="handleDeleteAccount(account)" />
          </div>
          <div class="icon">
            <a-icon type="bank" />
          </div>
          <div class="body">
            <h2 class="title">{{ account.bank }}</h2>
            <p class="description">
              {{ labels.accountType[account.account_type] }} -
              <span>{{ account.account | maskAccount }}</span>
            </p>
          </div>
        </div>
      </a-card>
      <a-card
        size="small"
        @click="handleNewAccount"
        :class="{ selected: dataSource.length === selected?.index }"
        :loading="loading"
        class="card-account-item"
      >
        <div class="account-container">
          <div class="icon">
            <a-icon type="plus" />
          </div>
          <div class="body">
            <h2 class="title">Agregar Cuenta</h2>
          </div>
        </div>
      </a-card>
    </div>
    <!-- FORM TO ADD NEW BANK ACCOUNT -->
    <div class="add-bank-account-form" v-if="isNewBankAccount">
      <ValidationObserver ref="userBankAccountObserver">
        <a-form
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(onSubmit)"
        >
          <a-row type="flex" :gutter="[8, 8]">
            <a-col :md="8" :sm="24" :xs="24">
              <SelectComponent
                v-model="form.bank"
                :data="banksDropdown"
                :label="labels.bank.label"
                rules="required"
                :placeholder="labels.bank.placeholder"
                sortOptionsBy="label"
                labelKey="label"
                valueKey="id"
                size="large"
                dropdownClassName="custom-select"
              />
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              <Input
                v-model="form.account"
                rules="required"
                vid="account"
                :placeholder="labels.account.placeholder"
                size="large"
                :allowClear="true"
                :label="labels.account.label"
              />
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              <SelectComponent
                v-model="form.account_type"
                :data="accountTypes"
                :label="labels.accountType.label"
                rules="required"
                :placeholder="labels.accountType.placeholder"
                sortOptionsBy="label"
                labelKey="label"
                valueKey="value"
                size="large"
                dropdownClassName="custom-select"
              />
            </a-col>
          </a-row>
          <a-row
            type="flex"
            :gutter="[8, 8]"
            justify="space-between"
            :style="{ alignItems: 'center' }"
          >
            <a-col :md="8" :sm="24" :xs="24">
              <Input
                v-model="form.identification_card"
                rules="required"
                vid="identification_card"
                :placeholder="labels.identificationCard.placeholder"
                size="large"
                :allowClear="true"
                :label="labels.identificationCard.label"
                v-themask="['###-#######-#']"
              />
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              <a-checkbox
                v-model="form.save"
                :label="labels.save.label"
                :checked="form.save"
              >
                {{ labels.save.label }}
              </a-checkbox>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            :gutter="[8, 8]"
            justify="space-between"
            :style="{ alignItems: 'center' }"
            v-if="!confirmed"
          >
            <a-col :md="24" :sm="24" :xs="24">
              <a-button
                type="primary"
                class="btn-submit"
                html-type="submit"
                :loading="loading"
                :disabled="loading"
                size="large"
                block
                ghost
              >
                {{ labels.confirmButtonText }}
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </div>
    <!-- SHOW ACCOUNT INFO -->
    <div class="show-account-info" v-if="!isNewBankAccount && selected?.id">
      <a-divider orientation="left" class="divider-item">{{
        labels.bankAccountInfoLabel
      }}</a-divider>
      <div class="account-info">
        <div class="account-info-item">
          <span>{{ labels.bankAndAccountTypeLabel }}</span>
          <span
            >{{ selected?.bank?.label || "" }} -
            {{ selected?.account_type?.label || "" }}</span
          >
        </div>
        <div class="account-info-item">
          <span>{{ labels.bankAccountNumberLabel }}</span>
          <span>{{ selected?.account }}</span>
        </div>
        <div class="account-info-item">
          <span>{{ labels.identificationCardLabel }}</span>
          <span>{{
            selected?.identification_card | mask("###-#######-#")
          }}</span>
        </div>
      </div>
    </div>
    <!-- ERRORS -->
    <div class="error-label" v-if="error">{{ labels.selectAccountError }}</div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
import { ValidationObserver } from "vee-validate";
import SelectComponent from "@/components/core/SelectPagination";
import Input from "@/components/core/VeeValidateForm/Input";
import { mask } from "vue-the-mask";
import { VueMaskFilter } from "v-mask";

const defaultState = {
  bank: "",
  account: "",
  account_type: "",
  identification_card: "",
  default: false,
  save: false,
};

export const ACCOUNT_TYPE = {
  SAVINGS: "SAVINGS",
  CHECKING: "CHECKING",
};

export default {
  name: "UserBankAccountComponent",
  components: {
    ValidationObserver,
    SelectComponent,
    Input,
  },
  directives: { themask: mask },
  filters: {
    mask: VueMaskFilter,
    maskAccount(value) {
      return value.replace(/\d(?=\d{4})/g, "*");
    },
  },
  props: {
    value: {
      type: [Object, undefined, null],
      default: null,
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  data() {
    return {
      selected: this.value,
      form: _.cloneDeep(defaultState),
      dataSource: [],
      loading: false,
      accountTypes: Object.values(ACCOUNT_TYPE).map((accountType) => ({
        value: accountType,
        label: this.labels?.accountType?.[accountType] || accountType,
      })),
      confirmed: false,
    };
  },
  async created() {
    await this.fetchBanks();
    await this.handleGetBankAccounts();
  },
  computed: {
    ...mapState("users", ["bankAccounts"]),
    ...mapState("systemConfig", ["banks"]),
    banksDropdown() {
      // SLUGIFY BANKS
      return _.uniq(this.banks).map((bank) => ({
        id: _.kebabCase(bank),
        value: bank,
        label: bank,
      }));
    },
    isNewBankAccount() {
      return this.selected?.index === this.dataSource.length;
    },
  },
  methods: {
    ...mapActions("users", ["getUserBankAccounts", "deleteUserBankAccount"]),
    ...mapActions("systemConfig", ["fetchBanks"]),
    async handleGetBankAccounts() {
      try {
        this.loading = true;
        await this.getUserBankAccounts();
      } catch (error) {
        this.dataSource = [];
        console.error(error);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          if (
            this.dataSource.length === 0 &&
            this.selected?.index === undefined
          ) {
            this.handleNewAccount();
          }
        });
      }
    },
    onSubmit() {
      this.confirmed = true;
      const data = {
        ...this.selected,
        ...this.form,
      };
      this.$emit("input", data);
      this.selected = data;
    },
    handleSelected({ account, index }) {
      this.selected = {
        ...account,
        account_type: {
          label: this.labels.accountType[account.account_type],
          value: account.account_type,
        },
        bank: {
          label: account.bank,
          value: account.bank,
        },
        index,
      };
    },
    handleNewAccount() {
      this.selected = { index: this.dataSource.length };
      this.resetForm();
    },
    handleDeleteAccount(account) {
      this.$confirm({
        title: this.labels.deleteAccountTitle,
        content: this.labels.deleteAccountContent,
        okText: this.labels.deleteAccountOkText,
        okType: "danger",
        cancelText: this.labels.deleteAccountCancelText,
        onOk: async () => {
          try {
            this.loading = true;
            await this.deleteUserBankAccount(account.id);
            await this.handleGetBankAccounts();
          } catch (error) {
            console.error(error);
          } finally {
            this.loading = false;
          }
        },
      });
    },
    resetForm() {
      this.form = _.cloneDeep(defaultState);
    },
  },
  watch: {
    bankAccounts: {
      handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.dataSource = newValue;
        }
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.selected = newValue;
        }
      },
      deep: true,
      immediate: true,
    },
    selected: {
      handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.$emit("input", newValue);
        }
      },
      deep: true,
    },
    form: {
      handler() {
        if (this.confirmed) {
          this.confirmed = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.user-bank-accounts {
  position: relative;
  width: 100%;
  display: block;
  .bank-accounts {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .card-account-item {
      width: 260px;
      height: 100px;
      border-radius: 10px;
      cursor: pointer;
      .ant-card-body {
        min-height: 100%;
      }
      ::v-deep .account-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        min-height: 75px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #f0f0f0;
          width: 40px;
          svg {
            font-size: 20px;
          }
        }
        .body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          width: calc(100% - 50px);
          .title {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
          }
          .description {
            font-size: 14px;
            color: #666;
            margin: 0;
            span {
              font-weight: 700;
            }
          }
        }
        .delete-btn {
          position: absolute;
          top: 16px;
          right: 4px;
          z-index: 1;
          svg {
            font-size: 20px;
            color: color(danger);
            cursor: pointer;
          }
        }
      }
      &.selected {
        border-color: color(c-hover);
        background-color: color(c-hover);
        ::v-deep .account-container {
          .icon {
            background-color: color(c-primary);
            color: color(c-hover);
          }
        }
      }
    }
  }
  .error-label {
    display: block;
    width: 100%;
    color: color(danger);
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .add-bank-account-form,
  .show-account-info {
    margin-top: 20px;
  }
  .add-bank-account-form {
    .btn-submit {
      border-color: color(c-hover);
      border-style: dashed;
    }
  }
  .show-account-info {
    color: white !important;
    ::v-deep .divider-item {
      color: white !important;
    }
    .account-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .account-info-item {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        span {
          font-size: 16px;
          &:first-child {
            width: 170px;
          }
          &:last-child {
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
